import React from 'react'
import { Card } from 'react-bootstrap'
import Link from 'next/link'
import Image from 'next/image'

const Product = ({ product }) => {
    return (
        <Card className='bg-transparent product-card' style={{ border: 'none' }}>
            <Link href={`/products/${product.slug}`}>
                <a style={{ overflow: 'hidden', borderRadius: '5%' }}><Card.Img src={product.image} variant='top' style={{ height: '100%', objectFit: 'cover' }} /></a>
            </Link>
            <Card.Body>
                <Link href={`/products/${product.slug}`} passHref>
                    <Card.Title as='div' className='d-flex flex-column justify-content-between flex-md-row'><strong>{product.name}</strong><i>{product.category.name}</i></Card.Title>
                </Link>
                {/* <Card.Text as='div'>
                    <Rating value={product.rating} text={`${product.numReviews} reviews`} />
                </Card.Text> */}
                <Card.Text>
                    €{product.price}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Product
