import useBreakpoint from 'hooks/useBreakpoint';
import React, { useEffect } from 'react'
import { Image } from 'react-bootstrap'

const Video = () => {
    const breakpoint = useBreakpoint();

    useEffect(() => {
        var videoLocation = document.getElementById("hero-video");
        if (['md', 'lg', 'xl'].includes(breakpoint)) {
            videoLocation.src = "/videos/video-treat-promo.mp4";
        };
    }, [breakpoint])
    return (
        <div className='video-wrapper'>
            <Image src="/images/slide_mobile.png" alt="img" />
            <video id="hero-video" width='100%' autoPlay muted loop type='video/mp4' style={{ borderRadius: '10 %' }} ></video>
            <p className='video-caption'>Book your favorite Genius</p>
        </div>
    )
}

export default Video
