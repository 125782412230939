import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import { listProducts } from '../redux/actions/productActions'
import { wrapper } from '../redux/store'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Video from '../components/Video'
import Link from 'next/link'
import { useRouter } from 'next/router'
import MissingGeniusForm from '../components/MissingGeniusForm'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

export default function HomePage() {
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(2);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const router = useRouter();
    const keyword = router.query.keyword
    const productList = useSelector(state => state.productList);
    const { error, products } = productList;

    useEffect(() => {
        products && setItems(products);
        error && setErrorMessage(error);
    }, [products, error])

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`/api/products?page=${pageNumber}`);
            setItems([...items, ...data.products])

            if (data.products.length === 0 || data.products.length < 16) {
                setHasMore(false)
            }
            setPageNumber(prev => prev + 1);
        } catch (error) {
            SpeechSynthesisErrorEvent()
        }

    }

    return (
        <>
            {!keyword ? <Video /> : <Link to='/' className='btn btn-light'>Go Back</Link>}
            {errorMessage ? <Message variant='danger'>{errorMessage}</Message> :
                <div className="my-5">
                    <InfiniteScroll

                        dataLength={items.length} //This is important field to render the next data
                        next={fetchData}
                        hasMore={hasMore}
                        loader={<Loader />}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        <Row>
                            {items?.map((product, index) => {
                                return <Col key={index} xs={6} sm={6} md={4} lg={3} xl={3}>
                                    <Product product={product} />
                                </Col>
                            })}
                        </Row>
                    </InfiniteScroll>
                </div>

            }
            <MissingGeniusForm />
        </>

    )
}

export const getServerSideProps = wrapper.getServerSideProps((store) => async ({ req, query }) => {
    await store.dispatch(listProducts(req, query?.keyword, query?.page || 1))
})

