import { sendEmail } from '@/redux/actions/mailActions'
import React, { useState } from 'react'
import { FormGroup, FormControl, Button, Form } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from './FormContainer'
import Message from './Message'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    email: yup.string().required().email(),
    genius: yup.string().required(),
}).required();

function MissingGeniusForm() {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
            genius: '',
        },
        resolver: yupResolver(schema)
    })

    const { error, loading, result } = useSelector(state => state.mail)

    const dispatch = useDispatch();
    const onSubmitHandler = (data, e) => {
        e.preventDefault();
        dispatch(sendEmail({
            subject: 'Missing Genius',
            message: `<h1>Missing Genius from ${data.email}</h1>
            <p><b>Email:</b></p>
            <p>${data.email}</p>
            <p><b>Genius:</b></p> 
            <p>${data.genius} </p>`
        }))
        reset();
    }

    return (
        <>
            <FormContainer md={4} onSubmit>
                <h1 className="">Didn&apos;t find your Genius? <div>Let us know!</div></h1>
                {error && <Message variant='danger'>{error}</Message>}
                {result && <Message variant='primary'>{result.message}</Message>}
                {loading && <Loader />}
                <Form onSubmit={handleSubmit(onSubmitHandler)}>
                    <FormGroup className='mb-3' controlId='email'>
                        <FormControl type='text' placeholder='Your Email'
                            isInvalid={errors.email}
                            {...register('email')}>

                        </FormControl>
                        <Form.Control.Feedback type='invalid'>{errors.email?.message}</Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup className='mb-3' controlId='genius'>
                        <FormControl type='text' placeholder='Your Genius'
                            isInvalid={errors.genius}
                            {...register('genius')}>

                        </FormControl>
                        <Form.Control.Feedback type='invalid'>{errors.genius?.message}</Form.Control.Feedback>
                    </FormGroup>
                    <Button type='submit' variant='primary' style={{ width: '100%' }}>
                        Send
                    </Button>
                </Form>
            </FormContainer>
        </>
    )
}

export default MissingGeniusForm
