import { Col, Container, Row } from 'react-bootstrap'

const FormContainer = ({ children, md, marginTop }) => {
    return (
        <Container className={marginTop ? marginTop : null}>
            <Row className='justify-content-md-center'>
                <Col xs={12} md={md ? md : false}>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

export default FormContainer
