
import axios from "axios"
import { SENDMAIL_FAIL, SENDMAIL_REQUEST, SENDMAIL_SUCCESS } from "../constants/mailConstants"


export const sendEmail = ({ to, subject, message }) => async (dispatch) => {
    try {
        dispatch({ type: SENDMAIL_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/mail', { to, subject, message }, config)

        dispatch({ type: SENDMAIL_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: SENDMAIL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}